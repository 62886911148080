import React from "react"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import { Link } from "gatsby"

import Service from "../pages/service"
import BlogPost from "../pages/blog-post"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} page="404">
        <Seo
          location={this.props.location}
          title="Home Equity loan Made Simple - Best Mortgage Brokers in Canada -
            RateLock | 404: Not Found"
        />
        <h1>Not Found</h1>
        <p>
          You just hit a route that doesn&#39;t exist.{" "}
          <Link to="/" style={{ fontSize: "inherit", fontWeight: "bold" }}>
            Click here to go back to the homepage
          </Link>
        </p>
      </Layout>
    )
  }
}

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    service: Service,
    blog_post: BlogPost
  }
})
