import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import { ImageCaption, Quote, Text, Embed } from "../components/Blog/slices"
import Seo from "../components/Seo"
import "../styles/pages/services.css"

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          )

        case "video_link":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Embed slice={slice} />}
            </div>
          )

        case "quote":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Quote slice={slice} />}
            </div>
          )

        case "image":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<ImageCaption slice={slice} />}
            </div>
          )
        case "heading":
          return (
            <h3
              key={index}
              className="blog-inner-heading homepage-slice-wrapper"
            >
              {slice.primary.heading.text}
            </h3>
          )
        default:
          return
      }
    })()
    return res
  })
}

const ServicesPostTemplate = ({
  heading,
  sub_heading,
  title,
  helmet,
  service_image,
  service_video,
  body
}) => {
  return (
    <section id="service">
      {helmet || ""}
      <div id="service-page" className="container">
        <div className="sections">
          {service_video && service_video.html ? (
            <div className="post-embed container">
              {/* <div>{RichText.render(slice.primary.embed_link.raw)}</div> */}
              <div dangerouslySetInnerHTML={{ __html: service_video.html }} />
            </div>
          ) : (
            service_image && (
              <img
                className="full-width-image margin-top-0 bg"
                alt={service_image.alt}
                src={service_image.fluid.src}
              />
            )
          )}
        </div>

        <h2 className="title uppercase primary-color">{title.text}</h2>
        <h1>{heading.text}</h1>
        <h2>{sub_heading.text}</h2>

        <div className="services-form-container">
          <Link to="/apply-now" className="get-started btn btn-animate">
            Get Started
          </Link>
        </div>
      </div>
      <div className="services-body">
        <PostSlices slices={body} />
        <Link to="/apply-now" className="btn-animate btn">
          Apply Now
        </Link>
      </div>
    </section>
  )
}

const Service = (props) => {
  // Define the Post content returned from Prismic
  const doc = props?.data?.prismicService
  let data
  if (doc) {
    data = doc.data
  }

  if (!doc || !data) return null
  return (
    <Layout location={props.location} page="service">
      <ServicesPostTemplate
        {...data}
        helmet={
          <Seo
            location={props.location}
            title={`${data.title.text} Services | RateLock`}
            description={data.sub_heading.text}
            image={data.service_image.fluid.src}
          />
        }
      />
    </Layout>
  )
}

export const serviceQuery = graphql`
  query Service($uid: String) {
    prismicService(uid: { eq: $uid }) {
      uid
      url
      data {
        title {
          text
        }
        heading {
          text
        }
        sub_heading {
          text
        }
        service_image {
          alt
          fluid(maxWidth: 1000, maxHeight: 400) {
            src
          }
        }
        service_video {
          html
        }
        body {
          __typename
          ... on PrismicServiceBodyHeading {
            id
            slice_type
            slice_label
            primary {
              heading {
                html
                text
                raw
              }
            }
          }
          ... on PrismicServiceBodyText {
            id
            slice_type
            slice_label
            primary {
              paragraph {
                html
                text
                raw
              }
            }
          }
          ... on PrismicServiceBodyVideoLink {
            id
            slice_type
            slice_label
            primary {
              embed_link {
                html
              }
            }
          }
          ... on PrismicServiceBodyQuote {
            id
            slice_type
            slice_label
            primary {
              quote {
                html
                text
                raw
              }
            }
          }
          ... on PrismicServiceBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                html
                text
                raw
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
            }
          }
        }
      }
    }
  }
`
export default withPreview(Service)
